import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.dev.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#00228F',
  colorPrimary1: '#0034DB',
  colorPrimary2: '#2B5DFF',
  colorPrimary3: '#7596FF',
  colorPrimary4: '#ACC7FF',
  colorPrimary5: '#E5EFFF',
  colorPrimary5Overlay: hexToRgba('#E5EFFF', 0.8),
  colorPrimary6: '#C8DBFF',

  // Accents colors
  colorAccentsError: '#D83E38',
  colorAccentsWarning: '#F1D400',
  colorAccentsSuccess: '#26881D',
  colorAccentsHighlight: '#FFE100',

  // Text colors
  colorTextPrimaryHighlight: '#2B5DFF', // colorPrimary2
  colorTextError: '#D83E38', // colorAccentsError

  // Error colors
  colorError: '#D83E38',
  colorErrorHover: '#B80044',
  colorErrorActive: '#D83E38',
  colorErrorDisabled: '#F280AA',
  colorErrorBackground: '#FFF0F0',

  // Warning colors
  colorWarning: '#F1D400',
  colorWarningBackground: '#FFFCE5',

  // Success colors
  colorSuccess: '#26881D',
  colorSuccessBackground: '#EBFAEA',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#071C5C', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextSecondary
};

export default colors;
