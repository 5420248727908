// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-hang.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.HANG,
  domain: 'hansegas.com',
  name: 'HanseGas GmbH',
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Schleswag-HeinGas-Platz',
    houseNumber: '1',
    zipCode: '25451',
    city: 'Quickborn',
  },
  districtCourt: 'Quickborn, Amtsgericht Pinneberg',
  commercialRegisterNumber: 'HRB 12571 PI',
  taxNumber: 'DE306647739',
  board: [],
  chairPerson: 'Steffen Bandelow, Malgorzata Cybulska, Lisa Hebenstreit',
  personResponsible: 'Ove Struck',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.hansegas.com/de/hansegas/impressum.html',
    privacyPolicy: 'https://www.hansegas.com/de/hansegas/datenschutz.html',
    termsAndConditions: 'https://www.hansegas.com/de/hansegas/agb.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 90,
      headerLogoHeight: 33,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 90,
      footerLogoHeight: 33,
      favicon: '/favicons/favicon-shng.ico',
    },
  },
  email: {
    name: 'HanseGas GmbH',
    logo: {
      src: 'https://smexnet.sh-netz.com/assets/logo-hang.png',
      alt: 'HanseGas GmbH Logo',
      height: '33',
      width: '90',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.chairPerson}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${
      baseData.personResponsible
    }<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Registergericht ${
      baseData.districtCourt
    } | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: 'smexnet@sh-netz.com',
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.chairPerson}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  moreToolsLinks: {
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '1501',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
